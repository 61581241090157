import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMe, get_sub_end_date } from '../../../../utils/mmc-api/api';

import * as styles from './Details.module.css';


import { SubmitHandler, useForm } from 'react-hook-form';
import { fetchUser } from '../../../../store/user';
import { UserData } from '../../../../types/User/User';
import { RootStoreState } from '@src/store';
import FormInput from '@src/components/Common/Input/Input';
import Spinner from '@src/components/UI/Spinner';


interface ProfileDetailsFormInputs {
  fullname: string;
  email: string;
  address: string;
  town: string;
  postcode: string;
}

interface ProfileDetailsFormProps {
  user: UserData;
}

// const postcodeRegex = /^(([A-Z][A-HJ-Y]?\d[A-Z\d]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?\d[A-Z]{2}|BFPO ?\d{1,4}|(KY\d|MSR|VG|AI)[ -]?\d{4}|[A-Z]{2} ?\d{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/;

const ProfileDetailsForm = ({ user }: ProfileDetailsFormProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProfileDetailsFormInputs>({
    defaultValues: {
      fullname: user.fullname,
      address: user.address,
      email: user.email,
      postcode: user.postcode,
      town: user.town,
    },
  });

  const dispatch = useDispatch();
  const storeUser = useSelector<RootStoreState>(state => state.user);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState();

  const onSubmit: SubmitHandler<ProfileDetailsFormInputs> = (data) => {
    setIsLoading(true);
    updateMe(data).then(()=>{
      setIsLoading(false);
      dispatch(fetchUser());
    });
  }

  useEffect(() => {
    const get_end_date = async () => {
        const response = await get_sub_end_date();
        if (response.email || !response) return;
        setSubscriptionEndDate(response);
    }
    if (storeUser) {
        get_end_date();
    }
  }, [storeUser]);
  
  return (
    <div className={`${styles.formContainer} profile-user-form`}>
      {subscriptionEndDate && (
          <p>
              Your membership renews on: {new Date(subscriptionEndDate).toDateString()}.
              Please refer to your invoice to cancel your membership or reach out at <a href="mailto:francesca@mymathscloud.com">francesca@mymathscloud.com</a>.
          </p>
      )}
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <FormInput label="Full Name" register={register} registerId="fullname" required type='text'/>
        {errors.fullname && <p>Full name is required.</p>}
        <FormInput label="Email" register={register} registerId="email" required disabled type='email'/>
        <FormInput label="Address" register={register} registerId="address" type='text'/>
        <FormInput label="Town" register={register} registerId="town" type='text' />
        <FormInput label="Postcode" register={register} registerId="postcode" type='text'/>
        {errors.postcode && <p>Please check your postcode</p>}
        <input className="update-profile-button" type="submit" value="Update profile details" />
      </form>
      <div className={`${styles.loadingContainer} ${isLoading ? styles.loading : ''}`}><Spinner /></div>
    </div>
  )
}

export default ProfileDetailsForm